import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import LinkBubble from "../components/linkBubble"

const GamesIndex = ({ data, location }) => {

  return (
    <StaticQuery query={graphql`
    {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {status: {eq: "release"}, type: {eq: "games"}}}) {
          edges {
            node {
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
              }
            }
          }
        }
      }
      
  `
  } 
    render={data=> (
      <Layout location={location} title={data.site.siteMetadata.title}>
      <SEO title="All games/apps" />
      <Bio />
      <LinkBubble title="← Home" to="/"></LinkBubble>
      <h1>All games/apps</h1>
      
      {data.allMarkdownRemark.edges.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
    )} />
  )
}

export default GamesIndex

